<template>
  <div class="finance">
    <!-- start 条件过滤区域 -->
    <el-row class="row">
      <el-col :span="24">
        <!-- 门店选择器 -->
        <el-select
          v-model="submitData.RangeStoresGuids"
          multiple
          collapse-tags
          clearable
          size="mini"
          placeholder="请选择门店"
          @change="onChangeStores"
        >
          <el-option
            v-for="item in storesList"
            :key="item.StoresGuid"
            :label="item.StoresName"
            :value="item.StoresGuid"
          >
          </el-option>
        </el-select>

        <!-- 基准门店选择器 -->
        <el-select
          v-if="authType == 'H'"
          v-model="submitData.TargetStoresGuid"
          size="mini"
          clearable
          placeholder="请选择基准门店"
        >
          <el-option
            v-for="item in manageStoresList"
            :key="item.StoresGuid"
            :label="item.StoresName"
            :value="item.StoresGuid"
          >
          </el-option>
        </el-select>

        <!-- 卡类型选择器 -->
        <el-select
          v-model="submitData.CardGuids"
          multiple
          collapse-tags
          clearable
          size="mini"
          placeholder="不限"
        >
          <el-option
            v-for="item in cardList"
            :key="item.CardGuid"
            :label="item.CardName"
            :value="item.CardGuid"
          >
          </el-option>
        </el-select>

        <!-- 日期选择器 -->
        <el-date-picker
          v-model="selectDateValue"
          size="mini"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="onChangeDate"
        >
        </el-date-picker>

        <!-- 查询按钮 -->
        <el-button type="primary" size="mini" @click="onSearch">
          查询
        </el-button>

        <!-- 自定义表格按钮 -->
        <el-button type="primary" size="mini" @click="onCustomDialog"
          >自定义表格</el-button
        >

        <!-- 导出表格按钮 -->
        <el-button
          type="primary"
          size="mini"
          :disabled="excelDisabled"
          @click="onExportExcel"
          >导出表格</el-button
        >
      </el-col>

      <el-col :span="24">
        <div class="tips">
          差额为正数表示本店（从他店）收入，负数表示（向他店）支出；A店（本店）<span
            class="our-store"
            >红色</span
          >表示本店应向他店跨店支出（出账）；<span class="other-store"
            >绿色</span
          >表示他店向本店跨店结算（入账）
        </div>
      </el-col>
    </el-row>
    <!-- end 条件过滤区域 -->

    <!-- start 跨店消费统计表 -->
    <el-table
      id="exprot-table"
      v-show="dataList != ''"
      show-summary
      stripe
      style="width: 100%"
      :max-height="tableMaxHeight"
      :data="dataList"
      :summary-method="onTotal"
      v-loading="loading"
    >
      <!-- 门店这一列是固定的 -->
      <el-table-column
        label="门店"
        prop="StoresName"
        width="160"
        show-overflow-tooltip
      ></el-table-column>

      <!-- 合并列表头（一级表头），由于表格需要自定义，只能依靠循环渲染列的数据，而列的循环顺序则依赖后端接口返回的data顺序，
      所以如果需要修改渲染出来的顺序就要让后端改 -->
      <el-table-column
        v-for="(item, index) in topList"
        :key="index"
        :label="item.Name"
        align="center"
      >
        <!-- 子表头（二级表头） -->
        <el-table-column
          v-if="item.Code == item2.TopCode"
          v-for="(item2, index2) in headList"
          :key="index2"
          :label="item2.Name"
          :prop="item2.DataKey"
          show-overflow-tooltip
          width="210"
        >
          <template slot-scope="scope">
            <span
              :class="
                scope.row[item2.DataKey]
                  ? tableColumnClassName(item.Name, item2.Name, item2.DataKey)
                  : ''
              "
              >{{ scope.row[item2.DataKey] }}</span
            >
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
    <!-- end 跨店消费统计表 -->

    <!-- start 自定义表格对话框 -->
    <CustomDialog
      ref="customDialog"
      @onConfirm="onSubmitFinance"
    ></CustomDialog>
    <!-- end 自定义表格对话框 -->
  </div>
</template>

<script>
import report from "@/api/report.js";
import CustomDialog from "../components/custom-dialog.vue";
export default {
  components: { CustomDialog },

  data() {
    return {
      submitData: {
        // 提交数据
        RangeStoresGuids: [], // 门店id数组
        TargetStoresGuid: [], // 基准门店id数组
        CardGuids: "", // 卡类型id
        BeginDate: "", // 开始日期
        EndDate: "", // 结束日期
      },
      headData: {}, // 自定义对话框表头源数据（尽可能不要污染）
      heads: [], // 自定义对话框的表头列表
      topList: [], // 表格的合并表头（一级表头）
      headList: [], // 表格的表头（二级表头）
      selectDateValue: [], // 日期选择器数据
      cardList: [], // 卡类型列表
      storesList: [], // 门店列表
      manageStoresList: [], // 基准门店列表
      dataList: [], // 表格内容数据列表
      tableMaxHeight: "", // 表格最大高度
      authType: "", // 当前账号等级 H 总店 M 各门店
      loading: false, // 表格加载
      excelDisabled: false, // 导出按钮禁用
    };
  },

  created() {
    this.getResize();
  },

  async mounted() {
    let accountInfo = JSON.parse(window.sessionStorage.getItem("accountInfo"));
    this.authType = accountInfo.authType;
    this.manageStoresList = accountInfo.manageStoresList || "";

    window.addEventListener("resize", this.getResize);

    await this.initDateState()
    await this.initCrossStoresFinance();
    await this.fetchCrossStoresFinance();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  computed: {
    // 判断表格内容返回class
    tableColumnClassName() {
      /* 主要的逻辑就是 除了一级表头为“充值”的，其他一级表头下的二级表头开头两个字是“本店”的，
      返回红色字体，“他店”返回绿色字体一级表头为“充值”下的二级表头，与之相反，
      “本店”返回绿色字体，“他店”返回红色字体 */
      return function(firstLabelName, secondLableName, secondDataKey, secondDataValue) {
        let str = secondLableName.slice(0, 2);
        if (firstLabelName == "充值" && str == "本店") {
          return "other-store";
        } else if (firstLabelName == "充值" && str == "他店") {
          return "our-store";
        } else if (str == "本店" || secondDataKey == "Total_KeepGoto" || (secondDataKey == 'Total_KeepDiff' && secondDataValue < 0)) {
          return "our-store";
        } else if (str == "他店" || secondDataKey == "Total_KeepCome" || (secondDataKey == 'Total_KeepDiff' && secondDataValue > 0)) {
          return "other-store";
        }
      };
    },
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - 220;
    },

    // 初始化下拉框用到的数据
    async initCrossStoresFinance() {
      try {
        let { data } = await report.initCrossStoresFinance();
        this.cardList = data.cardList;
        this.storesList = [
          { StoresGuid: "", StoresName: "全部" },
          ...data.storesList,
        ];
        // this.manageStoresList = this.manageStoresList || data.storesList;
        this.manageStoresList = data.storesList;
      } catch (e) {}
    },

    // 获取表头设置
    async fetchCrossStoresFinance() {
      try {
        let { data } = await report.fetchCrossStoresFinance();
        let { Heads, HeadTops } = data;
        this.headData = data;
        this.heads = Heads;
      } catch (e) {}
    },

    // 初始化表格数据信息
    async initPageData() {
      this.loading = true;
      try {
        let { data } = await report.queryCrossStoresFinance(this.submitData);
        let { DataList, HeadList, TopList, Total } = data;
        this.dataList = DataList;
        this.headList = HeadList;
        this.topList = TopList;
        this.total = Total;
      } finally {
        this.loading = false;
      }
    },
    
    // 初始化日期状态
    async initDateState() {
      let { beginDay, endDay } = await this.$store.dispatch(
        "onGetDateState"
      );
      this.selectDateValue = [beginDay, endDay];
      this.submitData.BeginDate = beginDay;
      this.submitData.EndDate = endDay;
    },

    // 日期改变事件
    onChangeDate(event) {
      this.$store.dispatch("onChangeDateState", {dayTime: event});
      this.initDateState()
    },

    // 改变门店选中事件
    onChangeStores(event) {
      let res = event.filter((item) => item == "");
      if (res.length > 0) {
        this.submitData.RangeStoresGuids = [];
        this.storesList.forEach((e) => {
          this.submitData.RangeStoresGuids.push(e.StoresGuid);
        });
        this.submitData.RangeStoresGuids.shift();
      }
    },

    // 查询按钮点击事件
    onSearch() {
      this.initPageData();
    },

    // 打开自定义表格对话框
    onCustomDialog() {
      this.$refs.customDialog.onShowDialog(this.headData);
    },

    // 设置表格确定后的返回函数
    onSubmitFinance(event) {
      let { Heads } = event;
      this.heads = Heads;
      this.saveCrossStoresFinance();
    },

    // 保存自定义表头设置
    async saveCrossStoresFinance() {
      try {
        // 保存自定义列多选的表头数据到数据库，让每次刷新获取表格数据的接口返回的是你保存的自定义列对应的表格数据
        let { errcode } = await report.saveCrossStoresFinance({
          Heads: this.heads,
        });
        if (errcode == 0) {
          this.$message.success("表格设置保存成功");
          await this.fetchCrossStoresFinance();
          if (this.submitData.RangeStoresGuids != "") {
            await this.initPageData();
          }
        }
      } catch (e) {
        //TODO handle the exception
      }
    },

    // 自定义合计
    onTotal({ columns, data }) {
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else {
          sums[index] = this.total[column.property];
        }
      });
      return sums;
    },

    // 导出表格按钮点击事件
    onExportExcel() {
      this.excelDisabled = true;
      this.$nextTick(() => {
        try {
          this.util.onExportExcel("#exprot-table", "跨店消费统计表");
        } finally {
          this.excelDisabled = false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.finance {
  .row {
    margin: 20px 0;

    .el-select,
    .el-input,
    .el-date-editor,
    .el-button {
      margin-left: 0;
      margin-right: 10px;
    }

    .el-select,
    .el-input {
      width: 100%;
      max-width: 240px !important;
    }

    .el-select {
      ::v-deep .el-tag {
        max-width: 140px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .date {
      width: 240px;
    }

    .tips {
      text-align: right;
      margin-top: 20px;
      color: #666;
    }
  }

  .our-store {
    color: red;
  }

  .other-store {
    color: limegreen;
  }
}
</style>
